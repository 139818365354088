<template>
  <section class="hero is-fullheight is-primary">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-vcentered is-centered">
          <div class="column is-one-third">
            <div class="card">
              <div class="card-image has-background-primary">
                <section class="hero">
                  <div class="container">
                    <figure class="image">
                      <img
                        src="@/assets/images/logo-green.png"
                        alt="Matrix For CRM"
                      >
                    </figure>
                  </div>
                </section>
                <br>
              </div>

              <div
                :class="{
                  'card-content': true,
                  'has-background-primary': $route.name === 'Redirect'
                }"
              >
                <slot name="content" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {

  name: 'AuthenticationLayout',

};
</script>

<style lang="scss" scoped></style>
